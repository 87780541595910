<template>
  <div>
    <v-card
      id="settings"
      class="pa-0"
      color="transparent"
      flat
      min-width="80"
      style="position: fixed; top: 40%; right: -35px; z-index: 1;"
    >
      <v-btn-toggle 
        v-model="typeSize"
        rounded
        class="conten-size-market"
        active-class="primary"
        dark
      >
        <v-btn value="default">
          <v-icon>mdi-map-marker-plus-outline</v-icon>
        </v-btn>
        <v-btn value="mini">
          <v-icon>mdi-map-marker-minus-outline</v-icon>
        </v-btn>
      </v-btn-toggle>
    </v-card>
  </div>
</template>
<script>
export default {
  name:'SizeMarket',
  props:{
    value:{
      type: String,
      default: '',
    }
  },
  data() {
    return {
      typeSize: this.value,
    }
  },
  watch: {
    value(val, oldVal) {
      if(val === oldVal) return 

      this.typeSize = val;
    },
    typeSize(val, oldVal) {
      if(val === oldVal) return 

      this.$emit('input', val);
    },
  }
}
</script>
<style lang="sass" scoped>
.conten-size-market
  flex-direction: column !important
  &.v-btn-toggle > .v-btn.v-btn:first-child
    border-bottom-left-radius: 0 !important
.conten-size-market.v-btn-toggle > .v-btn.v-btn:last-child
  border-top-right-radius: 0 !important
  border-bottom-left-radius: inherit
</style>